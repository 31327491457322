import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonModal
} from "@ionic/react";
import { addSharp } from "ionicons/icons";
import AbsenceList from "../components/AbsenceList";
import AbsenceModal from "../components/AbsenceModal";
import OrganisationSegment from "../components/OrganisationSegment";

export default function AbsencePage() {
    const [presentAbsenceModal, dismissAbsenceModal] = useIonModal(
        <AbsenceModal dismiss={() => dismissAbsenceModal()} />
    );

    return (
        <IonPage className="absence-page">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>Fraværende</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => presentAbsenceModal()}>
                            <IonIcon slot="icon-only" icon={addSharp} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <OrganisationSegment />
            </IonHeader>
            <IonContent fullscreen>
                <AbsenceList />
            </IonContent>
        </IonPage>
    );
}
