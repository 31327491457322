import {
    IonAvatar,
    IonIcon,
    IonImg,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonSearchbar,
    IonText,
    useIonAlert
} from "@ionic/react";
import { deleteDoc } from "firebase/firestore";
import { trashSharp } from "ionicons/icons";
import { useContext, useState } from "react";
import placeholder from "../assets/img/profile.png";
import { AuthContext } from "../providers/AuthProvider";
import { getUserDoc } from "../services/firebase";

export default function ContactList({ users }) {
    const [searchText, setSearchtext] = useState("");
    const [presentDeleteDialog] = useIonAlert();
    const { userIsSuperAdmin, authUser } = useContext(AuthContext);

    function showDeleteDialog(user) {
        presentDeleteDialog({
            header: "Slet bruger",
            message: `Ønsker du at slette ${user.name}?`,

            buttons: [
                { text: "Nej", role: "cancel" },
                { text: "Ja", role: "destructive", handler: () => deleteDoc(getUserDoc(user.id)) }
            ]
        });
    }

    const usersToDisplay = users?.filter(user => user.name.toLowerCase().includes(searchText));

    return (
        <>
            <IonSearchbar placeholder="Søg" onIonChange={e => setSearchtext(e.detail.value.toLocaleLowerCase())} />
            <IonList>
                {usersToDisplay?.map(user => (
                    <IonItemSliding key={user.id}>
                        <IonItem routerLink={`/contacts/${user.id}`} button>
                            <IonAvatar slot="start">
                                <IonImg src={user.img || placeholder} alt={user.name} />
                            </IonAvatar>
                            <IonLabel>
                                <IonText>
                                    <h2>
                                        {user.name || "Navn"}{" "}
                                        <span className="initials">({user.initials || "Initialer"})</span>
                                    </h2>
                                    <h3>{user.title || "Medarbejder"}</h3>
                                </IonText>
                            </IonLabel>
                        </IonItem>
                        {userIsSuperAdmin &&
                            user.email !== "boutime@boumatic.dk" &&
                            user.email !== "sac@boumatic.dk" &&
                            authUser.uid !== user.id && (
                                <IonItemOptions side="end">
                                    <IonItemOption color="danger" onClick={() => showDeleteDialog(user)}>
                                        <IonIcon icon={trashSharp} />
                                        &nbsp; Slet
                                    </IonItemOption>
                                </IonItemOptions>
                            )}
                    </IonItemSliding>
                ))}
            </IonList>
        </>
    );
}
