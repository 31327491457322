import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonListHeader,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar,
    useIonLoading,
    useIonModal,
    useIonToast,
    useIonAlert
} from "@ionic/react";
import { getAuth, sendPasswordResetEmail, signOut } from "firebase/auth";
import { onSnapshot, deleteDoc } from "firebase/firestore";
import {
    callSharp,
    checkboxSharp,
    homeSharp,
    keySharp,
    logOutSharp,
    mailSharp,
    phonePortraitSharp,
    pinSharp,
    printSharp
} from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import UserCard from "../components/UserCard";
import UserModal from "../components/UserModal";
import { AuthContext } from "../providers/AuthProvider";
import { getUserDoc } from "../services/firebase";

export default function ContactDetailPage({ myProfile }) {
    const { userCanEdit, authUser, userIsSuperAdmin } = useContext(AuthContext);
    const [selectedUser, setSelectedUser] = useState({});
    const [presentUserModal, dismissUserModal] = useIonModal(
        <UserModal user={selectedUser} dismiss={() => dismissUserModal()} />
    );
    const [presentDeleteDialog] = useIonAlert();

    const [presentToast] = useIonToast();
    const [showLoader, hideLoader] = useIonLoading();
    const { id } = useParams();

    const auth = getAuth();
    const history = useHistory();

    useEffect(() => {
        let uid = myProfile ? authUser.uid : id;
        let unsubscribe;
        if (uid) {
            unsubscribe = onSnapshot(getUserDoc(uid), doc => {
                setSelectedUser({ id: doc.id, ...doc.data() });
            });
        }
        return unsubscribe;
    }, [id, myProfile, authUser.uid]);

    function resetPassword() {
        showLoader();
        sendPasswordResetEmail(auth, authUser.email)
            .then(() => {
                hideLoader();
                presentToast({
                    header: "Tjek din mail!",
                    message: `Vi har sendt en mail til ${authUser.email}, hvor du kan nulstille din adgangskode. Der kan gå et par minutter før du modtager mailen. Tjek også SPAM eller clutter.`,
                    buttons: ["OK"],
                    duration: 7000,
                    color: "secondary"
                });
            })
            .catch(error => console.log(error));
    }

    function showDeleteDialog() {
        presentDeleteDialog({
            header: "Slet bruger",
            message: `Ønsker du at slette ${selectedUser.name}?`,

            buttons: [
                { text: "Nej", role: "cancel" },
                { text: "Ja", role: "destructive", handler: () => handleDeleteUser() }
            ]
        });
    }

    async function handleDeleteUser() {
        showLoader();
        await deleteDoc(getUserDoc(selectedUser.id));
        hideLoader();
        history.push("/contacts");
    }

    function handleSignOut() {
        signOut(auth).then(() => history.push("/sign-in"));
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton text="" defaultHref="/contacts" />
                    </IonButtons>
                    <IonTitle>{myProfile ? "Min Side" : selectedUser?.name}</IonTitle>
                    <IonButtons slot="end">
                        {userIsSuperAdmin &&
                            selectedUser.email !== "boutime@boumatic.dk" &&
                            selectedUser.email !== "sac@boumatic.dk" &&
                            authUser.uid !== selectedUser.id && (
                                <IonButton onClick={() => showDeleteDialog()}>Slet</IonButton>
                            )}
                        {(userCanEdit || selectedUser.id === authUser.uid) && (
                            <IonButton onClick={() => presentUserModal()}>Rediger</IonButton>
                        )}
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding" fullscreen>
                <UserCard user={selectedUser} myProfile={myProfile} presentUserModal={presentUserModal} />

                {selectedUser?.email && (
                    <IonCard>
                        <IonListHeader color="secondary">
                            <IonLabel>
                                <h2>Kontaktoplysninger</h2>
                            </IonLabel>
                        </IonListHeader>
                        {selectedUser?.office && (
                            <IonItem detail={false}>
                                <IonLabel>Kontor</IonLabel>
                                <IonText>{selectedUser?.office}</IonText>
                                <IonIcon slot="end" icon={pinSharp} color="secondary" />
                            </IonItem>
                        )}
                        {selectedUser?.phone && (
                            <IonItem href={"tel:" + selectedUser?.phone} detail={false}>
                                <IonLabel>Telefon</IonLabel>
                                <IonText>{selectedUser?.phone}</IonText>
                                <IonIcon slot="end" icon={callSharp} color="secondary" />
                            </IonItem>
                        )}
                        {selectedUser?.mobile && (
                            <IonItem href={"tel:" + selectedUser?.mobile} detail={false}>
                                <IonLabel>Mobil</IonLabel>
                                <IonText>{selectedUser?.mobile}</IonText>
                                <IonIcon slot="end" icon={phonePortraitSharp} color="secondary" />
                            </IonItem>
                        )}
                        {selectedUser?.fax && (
                            <IonItem detail={false}>
                                <IonLabel>Fax</IonLabel>
                                <IonText>{selectedUser?.fax}</IonText>
                                <IonIcon slot="end" icon={printSharp} color="secondary" />
                            </IonItem>
                        )}
                        {selectedUser?.email && (
                            <IonItem href={"mailto:" + selectedUser?.email} detail={false}>
                                <IonLabel>Mail</IonLabel>
                                <IonText>{selectedUser?.email}</IonText>
                                <IonIcon slot="end" icon={mailSharp} color="secondary" />
                            </IonItem>
                        )}
                    </IonCard>
                )}
                {(selectedUser?.address || selectedUser?.private) && (
                    <IonCard>
                        <IonListHeader color="secondary">
                            <IonLabel>
                                <h2>Private kontaktoplysninger</h2>
                            </IonLabel>
                        </IonListHeader>
                        {selectedUser?.address && (
                            <IonItem
                                href={"https://www.google.com/maps?q=" + selectedUser?.address}
                                target="_blank"
                                detail={false}>
                                <IonLabel>Adresse</IonLabel>
                                <IonText>{selectedUser?.address}</IonText>
                                <IonIcon slot="end" icon={homeSharp} color="secondary" />
                            </IonItem>
                        )}
                        {selectedUser?.private && (
                            <IonItem href={"tel:" + selectedUser?.private} detail={false}>
                                <IonLabel>Telefon</IonLabel>
                                <IonText>{selectedUser?.private}</IonText>
                                <IonIcon slot="end" icon={callSharp} color="secondary" />
                            </IonItem>
                        )}
                    </IonCard>
                )}

                <IonCard>
                    <IonListHeader color="secondary">
                        <IonLabel>
                            <h2>Bruger</h2>
                        </IonLabel>
                    </IonListHeader>

                    <IonItem button routerLink={`/absences/${selectedUser.id}`} detail={false}>
                        <IonLabel>Se fravær</IonLabel>

                        <IonIcon slot="end" icon={checkboxSharp} color="secondary" />
                    </IonItem>
                    {myProfile && (
                        <>
                            <IonItem button onClick={() => resetPassword()} detail={false}>
                                <IonLabel>Nulstil din adgangskode</IonLabel>

                                <IonIcon slot="end" icon={keySharp} color="secondary" />
                            </IonItem>

                            <IonItem button onClick={() => handleSignOut()} detail={false}>
                                <IonLabel>Log Ud</IonLabel>
                                <IonText>{selectedUser?.private}</IonText>
                                <IonIcon slot="end" color="danger" icon={logOutSharp} />
                            </IonItem>
                        </>
                    )}
                </IonCard>
            </IonContent>
        </IonPage>
    );
}
