import { IonSegment, IonSegmentButton } from "@ionic/react";
import { useContext } from "react";
import { AuthContext } from "../providers/AuthProvider";

export default function OrganisationSegment() {
    const { userIsBouTimeSuper, userIsSuperAdmin, selectedOrganisation, setSelectedOrganisation } =
        useContext(AuthContext);

    return (
        <>
            {(userIsBouTimeSuper || userIsSuperAdmin) && selectedOrganisation && (
                <IonSegment
                    className="organisation-segment"
                    color="primary"
                    value={selectedOrganisation}
                    onIonChange={e => setSelectedOrganisation(e.detail.value)}>
                    <IonSegmentButton value="BouMatic">BouMatic</IonSegmentButton>
                    <IonSegmentButton value="SAC">SAC Danmark</IonSegmentButton>
                </IonSegment>
            )}
        </>
    );
}
