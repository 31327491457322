import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonLoading,
    useIonModal
} from "@ionic/react";

import { onSnapshot, orderBy, query, where } from "firebase/firestore";
import { addSharp } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import ContactList from "../components/ContactList";
import OrganisationSegment from "../components/OrganisationSegment";
import UserModal from "../components/UserModal";
import { AuthContext } from "../providers/AuthProvider";
import { usersCollection } from "../services/firebase";

export default function ContactPage() {
    const { userCanEdit, selectedOrganisation } = useContext(AuthContext);
    const [users, setUsers] = useState(null);
    const [presentUserModal, dismissUserModal] = useIonModal(<UserModal dismiss={() => dismissUserModal()} />);
    const [showLoader, hideLoader] = useIonLoading();

    useEffect(() => {
        let unsubscribe;
        if (selectedOrganisation) {
            const q = query(usersCollection, where("organisation", "==", selectedOrganisation), orderBy("name"));
            unsubscribe = onSnapshot(q, querySnapshot => {
                const data = [];
                querySnapshot.forEach(doc => {
                    data.push({ id: doc.id, ...doc.data() });
                });
                setUsers(data);
                setTimeout(() => hideLoader(), 500);
            });
        }
        return unsubscribe;
    }, [hideLoader, selectedOrganisation]);

    useEffect(() => {
        if (users) {
            setTimeout(() => hideLoader(), 500);
        } else {
            showLoader();
        }
    }, [hideLoader, showLoader, users]);

    return (
        <IonPage className="contact-page">
            <IonHeader>
                <IonToolbar color="primary">
                    <IonTitle>Kontakter</IonTitle>
                    {userCanEdit && (
                        <IonButtons slot="end">
                            <IonButton onClick={() => presentUserModal()}>
                                <IonIcon slot="icon-only" icon={addSharp} />
                            </IonButton>
                        </IonButtons>
                    )}
                </IonToolbar>

                <OrganisationSegment />
            </IonHeader>
            <IonContent fullscreen>
                <ContactList users={users} />
            </IonContent>
        </IonPage>
    );
}
