import {
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonText,
    useIonAlert,
    useIonModal
} from "@ionic/react";
import { format, parseISO } from "date-fns";
import { deleteDoc } from "firebase/firestore";
import { trashSharp } from "ionicons/icons";
import { getEventDoc } from "../services/firebase";
import EventModal from "./EventModal";
import { AuthContext } from "../providers/AuthProvider";
import { useContext } from "react";

export default function EventItem({ event }) {
    const [presentEventModal, dismissEventModal] = useIonModal(
        <EventModal dismiss={() => dismissEventModal()} event={event} />
    );
    const [presentDeleteDialog] = useIonAlert();
    const { userCanEdit, authUser } = useContext(AuthContext);

    function getDate() {
        const start = format(parseISO(event.start), "dd/MM/yyyy");
        const end = format(parseISO(event.end), "dd/MM/yyyy");
        return start === end ? start : `${start} - ${end}`;
    }

    function getTime() {
        if (event.allDay) {
            return "Hele dagen";
        } else {
            const start = format(parseISO(event.start), "HH:mm");
            const end = format(parseISO(event.end), "HH:mm");
            return start === end ? start : `${start} - ${end}`;
        }
    }

    function showDeleteDialog() {
        presentDeleteDialog({
            header: "Slet fravær",
            message: `Ønsker du at slette "${event.text}"?`,
            buttons: [
                { text: "Nej", role: "cancel" },
                { text: "Ja", role: "destructive", handler: () => deleteDoc(getEventDoc(event.id)) }
            ]
        });
    }

    return (
        <IonItemSliding className="event-item">
            <IonItem
                className="ion-text-start"
                button
                onClick={() => (userCanEdit || event.createdBy === authUser.uid) && presentEventModal()}>
                <IonLabel>
                    <IonText color="secondary">
                        <h2>{getDate()}</h2>
                    </IonText>
                    <h3 className="ion-text-wrap">{event.text}</h3>
                    <p>{getTime()}</p>
                </IonLabel>
            </IonItem>
            {(userCanEdit || event.createdBy === authUser.uid) && (
                <IonItemOptions side="end">
                    <IonItemOption color="danger" onClick={() => showDeleteDialog(event)}>
                        <IonIcon icon={trashSharp} />
                        &nbsp; Slet
                    </IonItemOption>
                </IonItemOptions>
            )}
        </IonItemSliding>
    );
}
