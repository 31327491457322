import { IonCol, IonContent, IonDatetime, IonGrid, IonList, IonListHeader, IonPage, IonRow } from "@ionic/react";
import AbsenceList from "../components/AbsenceList";
import AppHeader from "../components/AppHeader";
import EventList from "../components/EventList";

export default function HomePage() {
    return (
        <IonPage className="home-page">
            <AppHeader />
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow>
                        <IonCol size-md="6">
                            <IonListHeader color="secondary">
                                <h2 className="list-header">Begivenheder</h2>
                            </IonListHeader>
                            <IonList className="event-list">
                                <IonDatetime
                                    locale="da-DK"
                                    presentation="date"
                                    firstDayOfWeek="1"
                                    size="cover"
                                    readonly
                                    mode="md"
                                    className="ion-hide-md-down"
                                />
                                <EventList />
                            </IonList>
                        </IonCol>
                        <IonCol size-md="6">
                            <IonListHeader color="secondary">
                                <h2 className="list-header">Fraværende</h2>
                            </IonListHeader>
                            <AbsenceList />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );
}
