import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar,
    useIonLoading
} from "@ionic/react";
import { format, parseISO } from "date-fns";
import { addDoc, getDoc, updateDoc } from "firebase/firestore";
import { closeSharp } from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { eventsCollection, getEventDoc, getUserDoc } from "../services/firebase";
import UserCard from "./UserCard";

export default function EventModal({ event, dismiss }) {
    const { authUser } = useContext(AuthContext);
    const accordionGroup = useRef(null);
    const [allDay, setAllDay] = useState(false);
    const [text, setText] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [selectedUser, setSelectedUser] = useState({});
    const [showLoader, hideLoader] = useIonLoading();

    useEffect(() => {
        if (event) {
            if (event.createdBy) {
                getDoc(getUserDoc(event.createdBy)).then(doc => {
                    setSelectedUser({ id: doc.id, ...doc.data() });
                });
            }
            setAllDay(event.allDay);
            setText(event.text);
            setStart(event.start);
            setEnd(event.end);
        }
    }, [event]);

    async function handleSubmit() {
        showLoader();
        if (text && start && end) {
            if (event) {
                await updateDoc(getEventDoc(event.id), {
                    allDay,
                    text,
                    start,
                    end,
                    organisation: selectedUser.organisation
                });
            } else {
                await addDoc(eventsCollection, {
                    allDay,
                    text,
                    start,
                    end,
                    createdBy: authUser.uid,
                    organisation: authUser.organisation
                });
            }
            dismiss();
            hideLoader();
        }
    }

    function displayDate(datetime) {
        return datetime && format(parseISO(datetime), "dd/MM/yyyy HH:mm");
    }

    function setEndDate(endDate) {
        if (endDate < start) {
            setStart(endDate);
        }
        setEnd(endDate);
    }

    function setStartDate(startDate) {
        if (startDate > end) {
            setEnd(startDate);
        }
        setStart(startDate);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonButton onClick={() => dismiss()}>
                            <IonIcon slot="icon-only" icon={closeSharp} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{event ? "Rediger begivenhed" : "Opret ny begivenhed"}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => handleSubmit()}>Gem</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonListHeader color="secondary">
                        <IonLabel>Begivenhed</IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonLabel>Beskrivelse</IonLabel>
                        <IonTextarea
                            placeholder="Skriv kort beskrivelse"
                            value={text}
                            onIonChange={e => setText(e.detail.value)}></IonTextarea>
                    </IonItem>

                    <IonListHeader color="secondary">
                        <IonLabel>Dato</IonLabel>
                    </IonListHeader>

                    <IonAccordionGroup ref={accordionGroup}>
                        <IonAccordion value="startDate">
                            <IonItem slot="header">
                                <IonLabel>Startdato</IonLabel>
                                <IonInput
                                    readonly
                                    value={displayDate(start)}
                                    id="date"
                                    placeholder="Vælg startdato og tidspunkt"
                                    slot="end"
                                    className="ion-text-end"></IonInput>
                            </IonItem>

                            {(start || !event) && (
                                <IonDatetime
                                    slot="content"
                                    size="cover"
                                    presentation="date-time"
                                    firstDayOfWeek="1"
                                    value={start}
                                    onIonChange={e => setStartDate(e.target.value)}></IonDatetime>
                            )}
                        </IonAccordion>

                        <IonAccordion value="endDate">
                            <IonItem slot="header">
                                <IonLabel>Slutdato</IonLabel>
                                <IonInput
                                    readonly
                                    value={displayDate(end)}
                                    id="date"
                                    placeholder="Vælg slutdato og tidspunkt"
                                    slot="end"
                                    className="ion-text-end"></IonInput>
                            </IonItem>
                            {(end || !event) && (
                                <IonDatetime
                                    slot="content"
                                    size="cover"
                                    presentation="date-time"
                                    firstDayOfWeek="1"
                                    value={end}
                                    onIonChange={e => setEndDate(e.target.value)}></IonDatetime>
                            )}
                        </IonAccordion>
                    </IonAccordionGroup>

                    <IonItem>
                        <IonLabel>Hele dagen</IonLabel>
                        <IonToggle value={allDay} checked={allDay} onIonChange={e => setAllDay(e.detail.checked)} />
                    </IonItem>

                    <IonListHeader color="secondary">
                        <IonLabel>Oprettet af</IonLabel>
                    </IonListHeader>
                    <UserCard user={event?.createdBy ? selectedUser : authUser} />
                </IonList>
            </IonContent>
        </IonPage>
    );
}
