import { initializeApp } from "firebase/app";
import { collection, doc, getFirestore } from "firebase/firestore";


import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBr7WSxdBoGSvkG5UdzOk9_JFsERAX5bjM",
    authDomain: "boutime-boumatic.firebaseapp.com",
    databaseURL: "https://boutime.firebaseio.com",
    projectId: "boutime-boumatic",
    storageBucket: "boutime-boumatic.appspot.com",
    messagingSenderId: "283793829775",
    appId: "1:283793829775:web:2390bc8cc94488e3aca5fa"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Reference to the storage service
export const storage = getStorage(app);


// Firestore DB
export const firestoreDB = getFirestore();

// users
export const usersCollection = collection(firestoreDB, "users");

export function getUserDoc(id) {
    return doc(firestoreDB, "users", id)
}

// absences
export const absencesCollection = collection(firestoreDB, "absences");

export function getAbsenceDoc(id) {
    return doc(firestoreDB, "absences", id);
}

// events
export const eventsCollection = collection(firestoreDB, "events");

export function getEventDoc(id) {
    return doc(firestoreDB, "events", id);
}

