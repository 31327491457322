import { IonCard, IonImg, IonItem, IonLabel } from "@ionic/react";
import placeholder from "../assets/img/profile.png";

export default function UserCard({ user, myProfile, presentUserModal }) {
    return (
        <IonCard class="contact-info">
            <IonItem button lines="none" detail={false} onClick={() => myProfile && presentUserModal()}>
                <IonImg slot="start" className="ion-padding-vertical" src={user?.img || placeholder} alt={user?.name} />
                <IonLabel>
                    <h2>
                        {user?.name || "Navn Navnesen"}&nbsp;
                        <span className="initials">({user?.initials || "XX"})</span>
                    </h2>
                    <h3>{user?.title || "Titel"}</h3>
                    <p>{user?.department || "Afdeling"}</p>
                </IonLabel>
            </IonItem>
        </IonCard>
    );
}
