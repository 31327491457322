import { IonHeader, IonImg, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import bouMaticLogo from "../assets/img/logo.png";
import sacLogo from "../assets/img/sac.png";
import { AuthContext } from "../providers/AuthProvider";
import packageJson from "./../../package.json";
import OrganisationSegment from "./OrganisationSegment";

export default function AppHeader({ title }) {
    const [logo, setLogo] = useState();
    const [headerTitle, setHeaderTitle] = useState("");
    const [presentToast] = useIonToast();

    const { authUser } = useContext(AuthContext);

    useEffect(() => {
        if (title) {
            setHeaderTitle(title);
        } else {
            if (authUser.organisation === "BouMatic") {
                setLogo(bouMaticLogo);
                setHeaderTitle("BouMatic A/S");
            } else if (authUser.organisation === "SAC") {
                setLogo(sacLogo);
                setHeaderTitle("SAC Danmark");
            }
        }
    }, [title, authUser]);
    return (
        <IonHeader>
            <IonToolbar color="primary">
                <IonTitle className="ion-hide-sm-down">{headerTitle}</IonTitle>
                <IonImg
                    src={logo}
                    className="toolbar-logo"
                    slot="end"
                    onClick={() =>
                        presentToast({
                            header: "BouTime Version",
                            message: `Version ${packageJson.version}`,
                            buttons: ["OK"],
                            duration: 4000,
                            color: "secondary"
                        })
                    }
                />
            </IonToolbar>
            <OrganisationSegment />
        </IonHeader>
    );
}
