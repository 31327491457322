import { Camera, CameraResultType } from "@capacitor/camera";
import { getDownloadURL, ref, uploadString } from "@firebase/storage";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonLoading
} from "@ionic/react";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { setDoc, updateDoc } from "firebase/firestore";
import { cameraSharp, closeSharp } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { getUserDoc, storage } from "../services/firebase";

export default function UserModal({ user, dismiss }) {
    const { userIsSuperAdmin, userCanEdit, selectedOrganisation } = useContext(AuthContext);
    const [name, setName] = useState("");
    const [initials, setIntials] = useState("");
    const [title, setTitle] = useState("");
    const [department, setDepartment] = useState("");
    const [type, setType] = useState("boutime");
    const [organisation, setOrganisation] = useState("");
    const [email, setEmail] = useState("");
    const [office, setOffice] = useState("");
    const [phone, setPhone] = useState("");
    const [mobile, setMobile] = useState("");
    const [fax, setFax] = useState("");
    const [password, setPassword] = useState("");
    const [address, setAddress] = useState("");
    const [privatePhone, setPrivatePhone] = useState("");
    const [img, setImg] = useState("");
    const [imgFile, setImgFile] = useState({});
    const [presentAlert] = useIonAlert();
    const [showLoader, hideLoader] = useIonLoading();

    useEffect(() => {
        if (user) {
            setName(user.name);
            setIntials(user.initials);
            setTitle(user.title);
            setDepartment(user.department);
            setType(user.type);
            setOrganisation(user.organisation || "BouMatic");
            setEmail(user.email);
            setOffice(user.office);
            setPhone(user.phone);
            setMobile(user.mobile);
            setFax(user.fax);
            setAddress(user.address);
            setPrivatePhone(user.private);
            setImg(user.img);
        } else {
            setOrganisation(selectedOrganisation);
        }
    }, [selectedOrganisation, user]);

    function handleSubmit() {
        const userToSave = {
            name,
            initials,
            title,
            department,
            type: type || "boutime",
            organisation: organisation || selectedOrganisation,
            email,
            office,
            phone,
            mobile,
            fax,
            address,
            private: privatePhone
        };

        if (user) {
            updateUser(userToSave);
        } else {
            presentAlert({
                header: "Angiv adgangskode",
                subHeader: "For at oprette en ny bruger, skal du angive din egen adgangskode.",
                inputs: [
                    {
                        name: "password",
                        placeholder: "Din adgangskode",
                        type: "password"
                    }
                ],
                buttons: [{ text: "Annuller" }, { text: "OK", handler: data => createUser(userToSave, data.password) }]
            });
        }
    }

    async function updateUser(userToUpdate) {
        showLoader();
        if (imgFile.dataUrl) {
            userToUpdate.img = await uploadImage(imgFile, user.id);
        }

        await updateDoc(getUserDoc(user.id), userToUpdate);
        dismiss();
        hideLoader();
    }

    async function createUser(newUser, adminPass) {
        showLoader();
        const auth = getAuth();
        const adminMail = auth.currentUser.email;

        // create new user w/ email and pass
        const newAuthUser = await createUserWithEmailAndPassword(
            auth,
            newUser.email,
            password.length > 0 ? password : "strangko1234"
        );

        if (imgFile.dataUrl) newUser.img = await uploadImage(imgFile, newAuthUser.user.uid);

        await setDoc(getUserDoc(newAuthUser.user.uid), newUser);

        //sign back in with current admin user
        await signInWithEmailAndPassword(auth, adminMail, adminPass);

        dismiss();
        hideLoader();
    }

    async function uploadImage(imageFile, uid) {
        const newImageRef = ref(storage, `profileImages/${uid}.${imageFile.format}`);
        await uploadString(newImageRef, imageFile.dataUrl, "data_url");
        const url = await getDownloadURL(newImageRef);
        return url;
    }

    async function takePicture() {
        const imageOptions = {
            quality: 80,
            width: 350,
            allowEditing: true,
            resultType: CameraResultType.DataUrl
        };
        const image = await Camera.getPhoto(imageOptions);
        setImgFile(image);
        setImg(image.dataUrl);
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonButton onClick={() => dismiss()}>
                            <IonIcon slot="icon-only" icon={closeSharp} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{user ? "Rediger" : "Opret ny bruger"}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => handleSubmit()}>Gem</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonListHeader color="secondary">
                        <IonLabel>Brugeroplysninger</IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonLabel position="stacked">Navn</IonLabel>
                        <IonInput
                            type="text"
                            placeholder="Indtast navn"
                            value={name}
                            onIonChange={e => setName(e.target.value)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Initialer</IonLabel>
                        <IonInput
                            type="text"
                            placeholder="Indtast initialer"
                            value={initials}
                            onIonChange={e => setIntials(e.target.value)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Titel</IonLabel>
                        <IonInput
                            type="text"
                            placeholder="Indtast titel på bruger"
                            value={title}
                            onIonChange={e => setTitle(e.target.value)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Afdeling</IonLabel>
                        <IonSelect
                            placeholder="Vælg afdeling"
                            value={department}
                            onIonChange={e => setDepartment(e.target.value)}>
                            <IonSelectOption value="Aftersales">Aftersales</IonSelectOption>
                            <IonSelectOption value="Book Keeping">Book Keeping</IonSelectOption>
                            <IonSelectOption value="Customer Service">Customer Service</IonSelectOption>
                            <IonSelectOption value="Forhandler">Forhandler</IonSelectOption>
                            <IonSelectOption value="Indkøb">Indkøb</IonSelectOption>
                            <IonSelectOption value="Lager">Lager</IonSelectOption>
                            <IonSelectOption value="Management">Management</IonSelectOption>
                            <IonSelectOption value="Produktion">Produktion</IonSelectOption>
                            <IonSelectOption value="Salg">Salg</IonSelectOption>
                            <IonSelectOption value="Technical Support">Technical Support</IonSelectOption>
                            <IonSelectOption value="Teknisk Afdeling">Teknisk Afdeling</IonSelectOption>
                            <IonSelectOption value="Andet">Andet</IonSelectOption>
                        </IonSelect>
                    </IonItem>

                    <IonItem
                        disabled={
                            !userCanEdit || user?.email === "boutime@boumatic.dk" || user?.email === "sac@boumatic.dk"
                        }>
                        <IonLabel>Brugertype</IonLabel>
                        <IonSelect
                            placeholder="Vælg brugertyper"
                            value={type}
                            onIonChange={e => setType(e.target.value)}>
                            <IonSelectOption value="admin-super" disabled={!userIsSuperAdmin}>
                                Superadministrator
                            </IonSelectOption>
                            <IonSelectOption value="admin">Administrator</IonSelectOption>
                            <IonSelectOption value="boutime-super">BouTime-superbruger</IonSelectOption>
                            <IonSelectOption value="boutime">BouTime-bruger</IonSelectOption>
                            <IonSelectOption value="kontaktperson">Kontaktperson</IonSelectOption>
                        </IonSelect>
                    </IonItem>

                    <IonItem
                        disabled={
                            !userCanEdit || user?.email === "boutime@boumatic.dk" || user?.email === "sac@boumatic.dk"
                        }>
                        <IonLabel>Organisation</IonLabel>
                        <IonSelect
                            placeholder="Vælg organisation"
                            value={organisation}
                            onIonChange={e => setOrganisation(e.target.value)}>
                            <IonSelectOption value="BouMatic">BouMatic</IonSelectOption>
                            <IonSelectOption value="SAC">SAC</IonSelectOption>
                        </IonSelect>
                    </IonItem>

                    <IonListHeader color="secondary">
                        <IonLabel>Kontaktoplysninger</IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonLabel position="stacked">Email</IonLabel>
                        <IonInput
                            type="email"
                            placeholder="Indtast brugerens email"
                            value={email}
                            onIonChange={e => setEmail(e.target.value)}
                            disabled={user}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Kontor</IonLabel>
                        <IonInput
                            type="text"
                            placeholder="Nummer eller navn på kontor"
                            value={office}
                            onIonChange={e => setOffice(e.target.value)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Telefon</IonLabel>
                        <IonInput
                            type="tel"
                            placeholder="Indtast brugerens telefonnummer"
                            value={phone}
                            onIonChange={e => setPhone(e.target.value)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Mobil</IonLabel>
                        <IonInput
                            type="tel"
                            placeholder="Indtast brugerens mobilnummer"
                            value={mobile}
                            onIonChange={e => setMobile(e.target.value)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Fax</IonLabel>
                        <IonInput
                            type="tel"
                            placeholder="Hvis brugeren anvender et faxnummer"
                            value={fax}
                            onIonChange={e => setFax(e.target.value)}
                        />
                    </IonItem>
                    {!user && userCanEdit && (
                        <>
                            <IonListHeader color="secondary">
                                <IonLabel>Adgangskode</IonLabel>
                            </IonListHeader>
                            <IonItem>
                                <ion-label class="ion-text-wrap">
                                    <p>Indtaster du ikke en adgangskode, vil standardadgangskoden blive anvendt.</p>
                                </ion-label>
                            </IonItem>
                            <IonItem>
                                <IonLabel position="stacked">Agangskode</IonLabel>
                                <IonInput
                                    type="password"
                                    placeholder="Indtast den nye brugers adgangskode"
                                    value={password}
                                    onIonChange={e => setPassword(e.target.value)}
                                    autocomplete="false"
                                />
                            </IonItem>
                        </>
                    )}
                    <IonListHeader color="secondary">
                        <IonLabel>Private kontaktoplysninger</IonLabel>
                    </IonListHeader>

                    <IonItem>
                        <IonLabel position="stacked">Adresse</IonLabel>
                        <IonInput
                            type="text"
                            placeholder="Hvis brugeren ønsker at dele privatadresse"
                            value={address}
                            onIonChange={e => setAddress(e.target.value)}
                        />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Telefon</IonLabel>
                        <IonInput
                            type="tel"
                            placeholder="Hvis brugeren ønsker at dele privatnummer"
                            value={privatePhone}
                            onIonChange={e => setPrivatePhone(e.target.value)}
                        />
                    </IonItem>
                    <IonListHeader color="secondary">
                        <IonLabel>Profilbillede</IonLabel>
                    </IonListHeader>
                    {img && <IonImg className="ion-padding" src={img} onClick={takePicture} />}
                    <IonItem onClick={takePicture} lines="none">
                        <IonLabel>Vælg billede</IonLabel>
                        <IonButton>
                            <IonIcon slot="icon-only" icon={cameraSharp} />
                        </IonButton>
                    </IonItem>
                </IonList>
            </IonContent>
        </IonPage>
    );
}
