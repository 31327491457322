import {
    IonAccordion,
    IonAccordionGroup,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    useIonLoading
} from "@ionic/react";
import { format, parseISO } from "date-fns";
import { addDoc, getDocs, orderBy, query, updateDoc, where } from "firebase/firestore";
import { closeSharp } from "ionicons/icons";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../providers/AuthProvider";
import { absencesCollection, getAbsenceDoc, usersCollection } from "../services/firebase";
import UserCard from "./UserCard";

export default function AbsenceModal({ absence, dismiss, selectedUser }) {
    const { authUser, selectedOrganisation, userCanEdit } = useContext(AuthContext);
    const accordionGroup = useRef(null);
    const [type, setType] = useState("");
    const [comment, setComment] = useState("");
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [showLoader, hideLoader] = useIonLoading();
    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState({});

    useEffect(() => {
        if (absence) {
            setType(absence.type);
            setComment(absence.comment);
            setStart(absence.start);
            setEnd(absence.end);
        }
        async function getUsers() {
            const q = query(usersCollection, where("organisation", "==", selectedOrganisation), orderBy("name"));
            const data = [];
            const docs = await getDocs(q);
            docs.forEach(doc => data.push({ uid: doc.id, ...doc.data() }));
            setUsers(data);
        }
        getUsers();
        setTimeout(() => hideLoader(), 500);

        setCurrentUser(selectedUser ? selectedUser : authUser);
    }, [absence, hideLoader, selectedOrganisation, selectedUser, authUser]);

    async function handleSubmit() {
        showLoader();
        if (absence) {
            await updateDoc(getAbsenceDoc(absence.id), {
                type,
                comment,
                start,
                end,
                uid: currentUser.uid,
                organisation: currentUser.organisation
            });
        } else {
            await addDoc(absencesCollection, {
                type,
                comment,
                start,
                end,
                uid: currentUser.uid,
                organisation: currentUser.organisation
            });
        }
        dismiss();
        hideLoader();
    }

    function displayDate(datetime) {
        return datetime ? format(parseISO(datetime), "dd/MM/yyyy") : "";
    }

    function formatDate(datetime) {
        return datetime ? format(parseISO(datetime), "yyyy-MM-dd") : "";
    }

    function setEndDate(endDate) {
        endDate = formatDate(endDate);
        if (endDate < start) {
            setStart(endDate);
        }

        setEnd(endDate);
        closeAccordion();
    }

    function setStartDate(startDate) {
        startDate = formatDate(startDate);
        if (startDate > end) {
            setEnd(startDate);
        }

        setStart(startDate);
        closeAccordion();
    }

    function closeAccordion() {
        if (!accordionGroup.current) {
            return;
        }
        const nativeEl = accordionGroup.current;

        nativeEl.value = undefined;
    }

    function changeUser(user) {
        if (user.uid) {
            setCurrentUser(user);
        }
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonButton onClick={() => dismiss()}>
                            <IonIcon slot="icon-only" icon={closeSharp} />
                        </IonButton>
                    </IonButtons>
                    <IonTitle>{absence ? "Rediger fravær" : "Opret nyt fravær"}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={() => handleSubmit()}>Gem</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <UserCard user={currentUser} />
                        {userCanEdit && (
                            <IonSelect
                                slot="end"
                                placeholder="Skift bruger"
                                onIonChange={e => changeUser(e.target.value)}>
                                {users?.map(user => (
                                    <IonSelectOption value={user} key={user.uid}>
                                        {user.name}
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        )}
                    </IonItem>
                    <IonListHeader color="secondary">
                        <IonLabel>Fravær</IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonLabel>Type</IonLabel>
                        <IonSelect
                            placeholder="Vælg fraværstype"
                            value={type}
                            onIonChange={e => setType(e.target.value)}>
                            <IonSelectOption value="andet">Andet</IonSelectOption>
                            <IonSelectOption value="ah">Arbejder hjemme</IonSelectOption>
                            <IonSelectOption value="ferie">Ferie</IonSelectOption>
                            <IonSelectOption value="fr">Forretningsrejse</IonSelectOption>
                            <IonSelectOption value="fri">Fri</IonSelectOption>
                            <IonSelectOption value="gt">Går tidligere</IonSelectOption>
                            <IonSelectOption value="it">Ikke tilstede</IonSelectOption>
                            <IonSelectOption value="kb">Kundebesøg</IonSelectOption>
                            <IonSelectOption value="møde">Møde</IonSelectOption>
                            <IonSelectOption value="ms">Møder senere</IonSelectOption>
                            <IonSelectOption value="syg">Syg</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Beskrivelse</IonLabel>
                        <IonTextarea
                            placeholder="Skriv kort beskrivelse"
                            value={comment}
                            onIonChange={e => setComment(e.detail.value)}></IonTextarea>
                    </IonItem>
                    <IonListHeader color="secondary">
                        <IonLabel>Dato</IonLabel>
                    </IonListHeader>

                    <IonAccordionGroup ref={accordionGroup}>
                        <IonAccordion value="startDate">
                            <IonItem slot="header">
                                <IonLabel>Startdato</IonLabel>
                                <IonInput
                                    readonly
                                    value={displayDate(start)}
                                    id="date"
                                    placeholder="Vælg startdato"
                                    slot="end"
                                    className="ion-text-end"></IonInput>
                            </IonItem>
                            {(start || !absence) && (
                                <IonDatetime
                                    slot="content"
                                    size="cover"
                                    locale="da-DK"
                                    presentation="date"
                                    firstDayOfWeek="1"
                                    value={start}
                                    onIonChange={e => setStartDate(e.target.value)}></IonDatetime>
                            )}
                        </IonAccordion>

                        <IonAccordion value="endDate">
                            <IonItem slot="header">
                                <IonLabel>Slutdato</IonLabel>
                                <IonInput
                                    readonly
                                    value={displayDate(end)}
                                    id="date"
                                    placeholder="Vælg slutdato"
                                    slot="end"
                                    className="ion-text-end"></IonInput>
                            </IonItem>
                            {(end || !absence) && (
                                <IonDatetime
                                    slot="content"
                                    size="cover"
                                    locale="da-DK"
                                    presentation="date"
                                    firstDayOfWeek="1"
                                    value={end}
                                    onIonChange={e => setEndDate(e.target.value)}></IonDatetime>
                            )}
                        </IonAccordion>
                    </IonAccordionGroup>
                </IonList>
            </IonContent>
        </IonPage>
    );
}
