import { getAuth, onAuthStateChanged } from "firebase/auth";
import { onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { getUserDoc } from "../services/firebase";
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(localStorage.getItem("isAuth")); // default value comes from localStorage
    const auth = getAuth();
    const [authUser, setAuthUser] = useState("");
    const [userCanEdit, setUserCanEdit] = useState(false);
    const [userIsAdmin, setUserIsAdmin] = useState(false);
    const [userIsSuperAdmin, setUserIsSuperAdmin] = useState(false);
    const [userIsBouTimeSuper, setUserIsBouTimeSuper] = useState(false);
    const [shownOrganisation, setShownOrganisation] = useState();

    useEffect(() => {
        onAuthStateChanged(auth, currentUser => {
            if (currentUser) {
                setIsAuth(true); // set isAuth to true
                localStorage.setItem("isAuth", true); // also, save isAuth in localStorage

                onSnapshot(getUserDoc(currentUser.uid), doc => {
                    const user = { uid: currentUser.uid, ...doc.data() };
                    console.log(user);
                    setAuthUser(user);
                    setUserCanEdit(
                        user.type === "admin" || user.type === "admin-super"
                    );
                    setUserIsAdmin(user.type === "admin");
                    setUserIsSuperAdmin(user.type === "admin-super");
                    setUserIsBouTimeSuper(user.type === "boutime-super");
                    setSelectedOrganisation(user.organisation);
                });
            } else {
                // user is not authenticated / not signed in
                setIsAuth(false); // set isAuth to false
                localStorage.removeItem("isAuth"); // remove isAuth from localStorage
            }
        });
    }, [auth]);

    function setSelectedOrganisation(organisation) {
        localStorage.setItem("selectedOrganisation", organisation);
        setShownOrganisation(organisation);
    }

    return (
        <AuthContext.Provider
            value={{
                authUser,
                isAuth,
                userCanEdit,
                userIsAdmin,
                userIsSuperAdmin,
                userIsBouTimeSuper,
                selectedOrganisation: shownOrganisation,
                setSelectedOrganisation
            }}>
            {children}
        </AuthContext.Provider>
    );
};
