import { IonItem, IonLabel, IonList, IonListHeader, useIonLoading } from "@ionic/react";
import { format } from "date-fns";
import { onSnapshot, orderBy, query, startAt, where } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import AbsenceItem from "../components/AbsenceItem";
import { AuthContext } from "../providers/AuthProvider";
import { absencesCollection } from "../services/firebase";

export default function EventList() {
    const [todaysAbsences, setTodaysAbsences] = useState(null);
    const [futureAbsences, setFutureAbsences] = useState(null);
    const { selectedOrganisation } = useContext(AuthContext);
    const [showLoader, hideLoader] = useIonLoading();

    useEffect(() => {
        let unsubscribe;
        if (selectedOrganisation) {
            const todaysDate = new Date();
            const now = new Date(format(todaysDate, "yyyy-MM-dd"));
            todaysDate.setDate(todaysDate.getDate() - 15);
            const fetchFromDate = format(todaysDate, "yyyy-MM-dd");

            const q = query(
                absencesCollection,
                where("organisation", "==", selectedOrganisation),
                orderBy("start"),
                startAt(fetchFromDate)
            );
            unsubscribe = onSnapshot(q, snapshot => {
                const todays = [];
                const future = [];
                snapshot.forEach(doc => {
                    const absence = { id: doc.id, ...doc.data() };

                    const start = new Date(absence.start);
                    const end = new Date(absence.end);

                    if (now >= start && end >= now) {
                        todays.push(absence);
                    } else if (now < start) {
                        future.push(absence);
                    }
                });
                setTodaysAbsences(todays);
                setFutureAbsences(future);
                setTimeout(() => hideLoader(), 500);
            });
        }
        return unsubscribe;
    }, [hideLoader, selectedOrganisation]);

    useEffect(() => {
        if (todaysAbsences && futureAbsences) {
            setTimeout(() => hideLoader(), 500);
        } else {
            showLoader();
        }
    }, [todaysAbsences, futureAbsences, hideLoader, showLoader]);

    return (
        <IonList className="absence-list">
            <IonListHeader color="secondary">
                <IonLabel>I dag</IonLabel>
            </IonListHeader>
            {todaysAbsences?.length ? (
                todaysAbsences.map(absence => {
                    return <AbsenceItem absence={absence} key={absence.id} />;
                })
            ) : (
                <IonItem>
                    <IonLabel>Ingen fraværende</IonLabel>
                </IonItem>
            )}
            <section className="future">
                <IonListHeader color="secondary">
                    <IonLabel>Kommende</IonLabel>
                </IonListHeader>
                {futureAbsences?.length ? (
                    futureAbsences.map(absence => {
                        return <AbsenceItem absence={absence} key={absence.id} />;
                    })
                ) : (
                    <IonItem>
                        <IonLabel>Ingen fraværende</IonLabel>
                    </IonItem>
                )}
            </section>
        </IonList>
    );
}
