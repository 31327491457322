import {
    IonApp,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
    setupIonicReact
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { calendar, call, home, people, person } from "ionicons/icons";
import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import AbsenceDetailPage from "./pages/AbsenceDetailPage";
import AbsencePage from "./pages/AbsencePage";
import CalendarPage from "./pages/CalendarPage";
import ContactDetailPage from "./pages/ContactDetailPage";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import ProfilePage from "./pages/ProfilePage";
import SignInPage from "./pages/SignInPage";
import { AuthContext } from "./providers/AuthProvider";
import "./services/firebase";
import "./theme/styles.css";
import "./theme/variables.css";

setupIonicReact();

export default function App() {
    const { isAuth } = useContext(AuthContext);

    return (
        <IonApp>
            <IonReactRouter>
                <IonTabs>
                    <IonRouterOutlet>
                        <Route exact path="/home">
                            <ProtectedRoute auth={isAuth} component={<HomePage />} />
                        </Route>
                        <Route exact path="/absences">
                            <ProtectedRoute auth={isAuth} component={<AbsencePage />} />
                        </Route>
                        <Route path="/absences/:id">
                            <ProtectedRoute auth={isAuth} component={<AbsenceDetailPage />} />
                        </Route>
                        <Route exact path="/calendar">
                            <ProtectedRoute auth={isAuth} component={<CalendarPage />} />
                        </Route>
                        <Route exact path="/contacts">
                            <ProtectedRoute auth={isAuth} component={<ContactPage />} />
                        </Route>
                        <Route path="/contacts/:id">
                            <ProtectedRoute auth={isAuth} component={<ContactDetailPage />} />
                        </Route>
                        <Route exact path="/profile">
                            <ProtectedRoute auth={isAuth} component={<ProfilePage />} />
                        </Route>
                        <Route exact path="/sign-in">
                            <SignInPage />
                        </Route>
                        <Route exact path="/">
                            <Redirect to="/home" />
                        </Route>
                    </IonRouterOutlet>

                    <IonTabBar slot="bottom" className={!isAuth && "ion-hide"}>
                        <IonTabButton tab="home" href="/home">
                            <IonIcon icon={home} />
                            <IonLabel>Hjem</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="calendar" href="/calendar">
                            <IonIcon icon={calendar} />
                            <IonLabel>Kalender</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="absences" href="/absences">
                            <IonIcon icon={people} />
                            <IonLabel>Fraværende</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="contacts" href="/contacts">
                            <IonIcon icon={call} />
                            <IonLabel>Kontakter</IonLabel>
                        </IonTabButton>
                        <IonTabButton tab="profile" href="/profile">
                            <IonIcon icon={person} />
                            <IonLabel>Min Side</IonLabel>
                        </IonTabButton>
                    </IonTabBar>
                </IonTabs>
            </IonReactRouter>
        </IonApp>
    );
}
