import {
    IonButton,
    IonContent,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonPage,
    IonText,
    useIonLoading,
    useIonToast
} from "@ionic/react";
import { useState } from "react";

import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import boutimeLogo from "../assets/img/boutime.png";

export default function SignInPage() {
    const [errorMessage, setErrorMessage] = useState("");
    const auth = getAuth();
    const [presentToast] = useIonToast();
    const [presentLoader, dismissLoader] = useIonLoading();
    const [mail, setMail] = useState("");

    function signIn(event) {
        event.preventDefault();
        const mail = event.target.mail.value; // mail value from inout field in sign in form
        const password = event.target.password.value; // password value from inout field in sign in form

        signInWithEmailAndPassword(auth, mail, password)
            .then(userCredential => {
                setTimeout(() => {
                    window.location.replace("/home");
                }, 100);
            })
            .catch(error => {
                setMessage(error.code);
            });
    }

    function setMessage(errorCode) {
        let message = "";
        switch (errorCode) {
            case "auth/wrong-password":
                message = "Du har indtastet forkert adgangskode. Prøv igen.";
                break;
            case "auth/invalid-email":
                message = "Du har ikke indtastet en emailadresse. Prøv igen.";
                break;
            case "auth/email-already-in-use":
                message = "Emailadressen er allerede i brug. Prøv igen eller kontakt administratoren.";
                break;
            case "auth/user-not-found":
                message =
                    "Brugeren eksisterer ikke, eller også har du indtastet forkert email. Prøv igen eller kontakt administratoren.";
                break;
            default:
                message = "Der er desværre opstået en fejl. Prøv igen.";
        }
        setErrorMessage(message);
    }

    function resetPassword(e) {
        if (mail) {
            setErrorMessage("");
            presentLoader();
            sendPasswordResetEmail(auth, mail)
                .then(() => {
                    dismissLoader();
                    presentToast({
                        header: "Tjek din mail!",
                        message: `Vi har sendt en mail til ${mail}, hvor du kan nulstille din adgangskode. Der kan gå et par minutter før du modtager mailen. Tjek også SPAM eller clutter.`,
                        buttons: ["OK"],
                        duration: 7000,
                        color: "secondary"
                    });
                })
                .catch(error => console.log(error));
        } else {
            setErrorMessage("Indtast din mail for at nulstille din adgangskode.");
        }
    }

    return (
        <IonPage className="sign-in-page">
            <IonContent color="primary-tint">
                <IonList>
                    <section className="sign-in-form-container">
                        <IonImg src={boutimeLogo} class="ion-margin" />
                        <form onSubmit={signIn} className="sign-in-form ion-padding">
                            <IonItem>
                                <IonLabel position="floating">Email</IonLabel>
                                <IonInput
                                    type="email"
                                    name="mail"
                                    value={mail}
                                    onIonChange={e => setMail(e.target.value)}></IonInput>
                            </IonItem>

                            <IonItem>
                                <IonLabel position="floating">Adgangskode</IonLabel>
                                <IonInput type="password" name="password"></IonInput>
                            </IonItem>
                            {errorMessage && (
                                <IonItem>
                                    <IonText color="danger">
                                        <p>{errorMessage}</p>
                                    </IonText>
                                </IonItem>
                            )}
                            <section className="ion-margin-top">
                                <IonButton color="primary" expand="block" type="submit">
                                    Log Ind
                                </IonButton>
                                <IonButton color="secondary" expand="block" onClick={resetPassword}>
                                    Nulstil Adgangskode
                                </IonButton>
                            </section>
                        </form>
                    </section>
                </IonList>
            </IonContent>
        </IonPage>
    );
}
