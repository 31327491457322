import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonContent,
    IonHeader,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonLoading
} from "@ionic/react";
import { format } from "date-fns";
import { endBefore, onSnapshot, orderBy, query, startAt, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import AbsenceItem from "../components/AbsenceItem";
import UserCard from "../components/UserCard";
import { absencesCollection, getUserDoc } from "../services/firebase";

export default function AbsenceDetailPage() {
    const [selectedUser, setSelectedUser] = useState({});
    const [todays, setTodays] = useState(null);
    const [future, setFuture] = useState(null);
    const [previous, setPrevious] = useState(null);
    const { id } = useParams();
    const [showLoader, hideLoader] = useIonLoading();

    useEffect(() => {
        setPrevious([]);
        let unsubscribe;
        if (id) {
            onSnapshot(getUserDoc(id), doc => {
                setSelectedUser({ uid: doc.id, ...doc.data() });
            });
            const todaysDate = new Date();
            const now = new Date(format(todaysDate, "yyyy-MM-dd"));
            todaysDate.setDate(todaysDate.getDate() - 15);
            const fetchFromDate = format(todaysDate, "yyyy-MM-dd");
            const q = query(absencesCollection, where("uid", "==", id), orderBy("start"), startAt(fetchFromDate));
            unsubscribe = onSnapshot(q, snapshot => {
                const todays = [];
                const future = [];
                snapshot.forEach(doc => {
                    const absence = { id: doc.id, ...doc.data() };

                    const start = new Date(absence.start);
                    const end = new Date(absence.end);

                    if (now >= start && end >= now) {
                        todays.push(absence);
                    } else if (now < start) {
                        future.push(absence);
                    }
                });
                setTodays(todays);
                setFuture(future);
            });
        }
        return unsubscribe;
    }, [id]);

    async function getPreviousAbsences() {
        const todaysDate = new Date();
        todaysDate.setDate(todaysDate.getDate());
        const fetchFromDate = format(todaysDate, "yyyy-MM-dd");
        const q = query(
            absencesCollection,
            where("uid", "==", id),
            orderBy("start"),
            startAt("2021-01-01"),
            endBefore(fetchFromDate)
        );

        onSnapshot(q, docs => {
            showLoader();
            const data = [];
            docs.forEach(doc => data.push({ id: doc.id, ...doc.data() }));
            setPrevious(data.reverse());
            setTimeout(() => hideLoader(), 500);
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonButtons slot="start">
                        <IonBackButton text="" defaultHref="/absences" />
                    </IonButtons>
                    <IonTitle>{selectedUser?.name || "Navn på fraværende"}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding absence-info" fullscreen>
                <UserCard user={selectedUser} />
                {todays?.length > 0 && (
                    <IonCard>
                        <IonListHeader color="secondary">
                            <IonLabel>
                                <h2>I dag</h2>
                            </IonLabel>
                        </IonListHeader>
                        <IonList className="absence-list">
                            {todays?.map(absence => {
                                return <AbsenceItem absence={absence} key={absence.id} detailPage={true} />;
                            })}
                        </IonList>
                    </IonCard>
                )}
                {future?.length > 0 && (
                    <IonCard>
                        <IonListHeader color="secondary">
                            <IonLabel>
                                <h2>Kommende</h2>
                            </IonLabel>
                        </IonListHeader>
                        <IonList className="absence-list">
                            {future?.map(absence => {
                                return <AbsenceItem absence={absence} key={absence.id} detailPage={true} />;
                            })}
                        </IonList>
                    </IonCard>
                )}

                <IonCard>
                    <IonListHeader color="secondary">
                        <IonLabel>
                            <h2>Tidligere</h2>
                        </IonLabel>
                    </IonListHeader>
                    {previous?.length > 0 ? (
                        <IonList className="absence-list">
                            {previous?.map(absence => {
                                return <AbsenceItem absence={absence} key={absence.id} detailPage={true} />;
                            })}
                        </IonList>
                    ) : (
                        <IonButton expand="block" className="ion-margin" onClick={() => getPreviousAbsences()}>
                            Vis tidligere fravær
                        </IonButton>
                    )}
                </IonCard>
            </IonContent>
        </IonPage>
    );
}
