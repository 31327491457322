import {
    IonAvatar,
    IonIcon,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    useIonAlert,
    useIonModal
} from "@ionic/react";
import { format, parseISO } from "date-fns";
import { deleteDoc, getDoc } from "firebase/firestore";
import { trashSharp } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import placeholder from "../assets/img/profile.png";
import { AuthContext } from "../providers/AuthProvider";
import { getAbsenceDoc, getUserDoc } from "../services/firebase";
import AbsenceModal from "./AbsenceModal";

export default function AbsenceItem({ absence, detailPage, selectedUser }) {
    const { userCanEdit, authUser } = useContext(AuthContext);
    const [presentDeleteDialog] = useIonAlert();
    const [currentUser, setCurrentUser] = useState({});

    const history = useHistory();
    const [presentAbsenceModal, dismissAbsencerModal] = useIonModal(
        <AbsenceModal absence={absence} selectedUser={currentUser} dismiss={() => dismissAbsencerModal()} />
    );

    useEffect(() => {
        async function getUser() {
            const doc = await getDoc(getUserDoc(absence.uid));
            setCurrentUser({ uid: doc.id, ...doc.data() });
        }

        if (selectedUser) {
            setCurrentUser(selectedUser);
        } else {
            getUser();
        }
        getUser();
    }, [absence.uid, selectedUser]);

    function getDate() {
        const start = format(parseISO(absence.start), "dd/MM/yyyy");
        const end = format(parseISO(absence.end), "dd/MM/yyyy");
        return start === end ? start : `${start} - ${end}`;
    }

    function handleItemClick() {
        if (detailPage) {
            if (userCanEdit || absence.uid === authUser.uid) presentAbsenceModal();
        } else {
            history.push(`/absences/${absence.uid}`);
        }
    }

    function showDeleteDialog(absence) {
        presentDeleteDialog({
            header: "Slet fravær",
            message: `Ønsker du at slette "${absence.comment}"?`,

            buttons: [
                { text: "Nej", role: "cancel" },
                { text: "Ja", role: "destructive", handler: () => deleteDoc(getAbsenceDoc(absence.id)) }
            ]
        });
    }

    return (
        <IonItemSliding>
            <IonItem button onClick={() => handleItemClick()}>
                <IonAvatar slot="start">
                    <img src={currentUser.img || placeholder} alt={currentUser.name} />
                </IonAvatar>

                <IonLabel>
                    <h2>
                        {currentUser.name} <span className="initials">({currentUser.initials})</span>
                    </h2>

                    <h3>{getDate()}</h3>
                    <p>{absence.comment}</p>
                </IonLabel>
                <ion-text slot="end" class={"type " + absence.type}>
                    {absence.type}
                </ion-text>
            </IonItem>
            {(userCanEdit || absence.uid === authUser.uid) && (
                <IonItemOptions side="end">
                    <IonItemOption color="danger" onClick={() => showDeleteDialog(absence)}>
                        <IonIcon icon={trashSharp} />
                        &nbsp; Slet
                    </IonItemOption>
                </IonItemOptions>
            )}
        </IonItemSliding>
    );
}
